<template>
  <div v-if="!errorFlag" class="d-flex align-items-center ustify-content-md-center">
    <div id="market_history" class="plots"></div>
    <b-overlay :show="loading" no-wrap></b-overlay>
  </div>
</template>

<script>
 const ptBRNumber = { decimal: ',',                      
                      thousands: '.',
                      grouping: [3],
                      currency: ['', 'R$'] };
 const ptBRDate = { dateTime: '%A, %e. de %B de %Y, %X',
                    date: '%d-%m-%Y',
                    time: '%H:%M:%S',
                    periods: ['AM', 'PM'],
                    days: [
                        'Domingo', 'Segunda', 'Terça', 'Quarta',
                        'Quinta', 'Sexta', 'Sábado'
                    ],
                    shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                    months: [
                        'Janeiro', 'Fevereiro', 'Março',
                        'Abril', 'Maio', 'Junho',
                        'Julho', 'Agosto', 'Setembro',
                        'Outubro', 'Novembro', 'Dezembro'
                    ],
                    shortMonths: [
                        'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
                        'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
                    ] };
 import { spec as plotSpec } from '../assets/vega/cnpj_market_history.vl';
 export default {
     data () {
	 return {
	     refreshed: 0,             
             loading: false,
             errorFlag: false,
	     plotdata: [],             
	     queryUrl: "/market_history?"             
	 }
     },
     methods: {
	 refresh() {
             this.errorFlag = false
             this.loading = true
	     const promise = axios.get(this.queryUrl)            
	     return promise.then(response => {		 
		 this.plotdata = response.data.data.map(item => item.attributes)                 
                 this.draw().then(() => {window.dispatchEvent(new Event('resize'))})
                 this.loading = false
	     }).catch( error => {
                 this.errorFlag = true
                 this.loading = false
                 throw error;
             })
	 },
	 async draw() {
	     let res = await vegaEmbed('#market_history',
                                       plotSpec,
                                       {"renderer":"svg",
                                        "actions": {
                                            "export": true,
                                            "source": false,
                                            "editor": false,
                                            "compiled": false
                                        },
                                        "formatLocale":ptBRNumber,
                                        "timeFormatLocale":ptBRDate});
	     return await res.view.insert('market_history_data',this.plotdata).runAsync();
	 }
     }
 }

</script>
