<template>
  <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        :limit="1"
        align="center"
        class="pagination-table"
        hide-goto-end-buttons>     
    <template #page>
      <span class="px-3" :id="'pagination-current-target' + id">        
        <span>{{ (currentPage-1)*perPage+1 }}</span>-
        <span v-if="currentPage*perPage>itemsCount">{{ itemsCount }}</span>        
        <span v-else>{{ currentPage*perPage }}</span>
        &nbsp;de&nbsp; 
        <span>{{ itemsCount }}</span>
      </span> 
      <b-popover
          :target="'pagination-current-target' + id"
          triggers="hover"
          placement="bottom"
          id="first-last-page-popover">
        <div class="w-auto">
          <b-form-group>
            <b-form-radio 
            v-model="currentPage" 
            value="1" 
            button 
            button-variant="white"
            v-if="currentPage>1">
            Primeira Página</b-form-radio>
            <b-form-radio 
            v-model="currentPage" 
            :max="Math.ceil(itemsCount/perPage)" 
            :value="Math.ceil(itemsCount/perPage)" 
            button 
            button-variant="white"
            v-if="currentPage<Math.ceil(itemsCount/perPage)">
            Última Página</b-form-radio>
          </b-form-group>
        </div>
      </b-popover>  
    </template>
    <template #prev-text>
      <a :id="'pagination-previous-target' + id">&#10094;</a>
      <b-popover
          :target="'pagination-previous-target' + id"
          triggers="hover"
          placement="bottomright">
        <div style="max-width: 7rem;">
          <b-form-group description="Digite o número da página desejada">
            <b-form-input
                v-model="currentPage"
                type="text"
                debounce="1000">
            </b-form-input>
          </b-form-group>
        </div>
      </b-popover>
    </template>
    <template #next-text>
      <a :id="'pagination-next-target' + id">&#10095;</a>
      <b-popover
          :target="'pagination-next-target' + id"
          triggers="hover"
          placement="bottom">
        <div style="max-width: 7rem;">
          <b-form-group>
            <b-form-input
                v-model="currentPage"
                type="text"
                debounce="1000">
            </b-form-input>
            <template #description>Digite o número da página desejada. <strong>Última página: {{ Math.ceil(itemsCount/perPage) }}</strong></template>
          </b-form-group>
        </div>
      </b-popover>
    </template>
  </b-pagination>
</template>

<script>

export default {  
  props: ["value", "perPage", "itemsCount", "totalRows", "id"],
  data() {
    return {
      currentPage: 1,
    }
  },
  watch: {
    currentPage() {
      this.$emit('input', this.currentPage)
    },
    value() {
      this.currentPage = this.value
    }
  },
  mounted() {
    this.currentPage = this.value
  }
}

</script>