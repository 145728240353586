export const spec = {
    "$schema": "https://vega.github.io/schema/vega/v5.json",
    "autosize": "none",
    "signals": [
	{
	    "name": "width",
	    "update": "containerSize()[0]"
	},
	{
	    "name": "height",
	    "update": "0.7*width"
	},
	{ "name": "tx", "update": "width / 2" },
	{ "name": "ty", "update": "height / 2" },
	{
	    "name": "scale",
	    "value": 640,
	    "on": [{
		"events": {"type": "wheel", "consume": true},
		"update": "clamp(scale * pow(1.0015, -event.deltaY * pow(16, event.deltaMode)), 300, 30000)"
	    }]
	},
	{
	    "name": "angles",
	    "value": [0,0],
	    "on": [{
		"events": "mousedown",
		"update": "[centerX, centerY]"
	    }]
	},
	{
	    "name": "cloned",
	    "value": null,
	    "on": [{
		"events": "mousedown",
		"update": "copy('projection')"
	    }]
	},
	{
	    "name": "start",
	    "value": null,
	    "on": [{
		"events": "mousedown",
		"update": "invert(cloned, xy())"
	    }]
	},
	{
	    "name": "drag", "value": null,
	    "on": [{
		"events": "[mousedown[event.ctrlKey], window:mouseup[event.ctrlKey]] > window:mousemove[event.ctrlKey]",
		"update": "invert(cloned, xy())"
	    }]
	},
	{
	    "name": "delta", "value": null,
	    "on": [{
		"events": {"signal": "drag"},
		"update": "[start[0] - drag[0], start[1] - drag[1]]"
	    }]
	},
	{
	    "name": "centerX", "value": -51.414,//-48.63,//
	    "on": [{
		"events": {"signal": "delta"},
		"update": "angles[0] + delta[0]"
	    }]
	},
	{
	    "name": "centerY", "value": -14.2356,//-22.55,//
	    "on": [{
		"events": {"signal": "delta"},
		"update": "angles[1] + delta[1]"
	    }]
	},
	{
	    "name": "toggleselect", "value": 0,
	    "on": [{
		"events": "shape:click[!event.ctrlKey]",
		"update": "[if(item().selected,0,1), item().datum]"
	    }]
	},
    ],

    "projections": [
	{
	    "name": "projection",
	    "type": "equirectangular",
	    "scale": {"signal": "scale"},
	    "center": [{"signal": "centerX"}, {"signal": "centerY"}],
	    "translate": [{"signal": "tx"}, {"signal": "ty"}]
	}
    ],

    "data": [
	{
	    "name": "muncodes",
	    "url": "/data/ibge/br_mun_map.json"
	},
	{
	    "name": "munselected",
	    "on": [
		{"trigger": "toggleselect[0]", "insert": "toggleselect[1]"},
		{"trigger": "!toggleselect[0]", "remove": "toggleselect[1]"}
	    ]
	},
	{
	    "name": "ufmap",
	    "url": "REPLACEME_WITH_THE_MAP_URL",
	    "format": {
		"type": "topojson",
		"feature": "REPLACEME_WITH_MAIN_FEATURE_NAME"
	    },
	    "transform": [
		{
		    "type": "lookup",
		    "from": "muncodes",
		    "key": "value",
		    "fields": ["id"],
		    "values": ["text"],
		    "as": ["munname"]
		}
	    ]
	}	
    ],
    "marks": [
	{
	    "type": "shape",
	    "from": {"data": "ufmap"},
	    "encode": {
		"enter": {
		    "strokeWidth": {"value": 0.5},
		    "stroke": {"value": "#bbb"},
		    "fill": {"value": "#e5e8d3"},
		    "tooltip": {"signal": "datum.munname"},
		    "selected": {"value": 0}
		}
	    },
	    "transform": [
		{ "type": "geoshape", "projection": "projection" }
	    ]
	},
	{
	    "type": "shape",
	    "from": {"data": "munselected"},
	    "encode": {
		"update": {
		    "fill": {"value": "#1e1ee1"},
		    "fillOpacity": {"value": 0.7},
		    "tooltip": {"signal": "datum.munname"},		    
		    "selected": {"value": 1}
		}
	    },
	    "transform": [
		{ "type": "geoshape", "projection": "projection" }
	    ]
	},
    ]
};
