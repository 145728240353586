// Import Vue
import './bootvue';

// Other app utilities
import * as VegaSpec from './utils/vega_change_spec';
window.VegaSpec = VegaSpec;

import FilterUiName from './components/FilterUiName';
import FilterUiNumericCode from './components/FilterUiNumericCode';
import FilterUi from './components/BaseFilterUi';
import FilterUiStates from './components/FilterUiStates';
import FilterUiCities from './components/FilterUiCities';
import FilterUiCnae from './components/FilterUiCnae';
import FilterUiDateInterval from './components/FilterUiDateInterval';
import FilterUiRange from './components/FilterUiRange';
import FilterUiBairros from './components/FilterUiBairros';
import CheckboxGroup from './components/BaseCheckboxGroup';
import InfoIcon from './components/InfoIcon';
import CnpjMapGoogle from './components/CnpjMapGoogle';
import CnpjMarketHistory from './components/CnpjChartHistory';
import BrCompaniesTable from './components/BrCompaniesTable';
import BrCompaniesPagination from './components/BrCompaniesPagination';

Vue.component('filter-ui-name', FilterUiName);
Vue.component('filter-ui-numeric-code', FilterUiNumericCode);
Vue.component('filter-ui', FilterUi);
Vue.component('filter-ui-states', FilterUiStates);
Vue.component('filter-ui-cities', FilterUiCities);
Vue.component('filter-ui-cnae', FilterUiCnae);
Vue.component('filter-ui-date-interval', FilterUiDateInterval);
Vue.component('filter-ui-range', FilterUiRange);
Vue.component('filter-ui-bairros', FilterUiBairros);
Vue.component('filter-checkbox-group', CheckboxGroup);
Vue.component('info-icon', InfoIcon);
Vue.component('cnpj-map-google', CnpjMapGoogle);
Vue.component('cnpj-market-history', CnpjMarketHistory);
Vue.component('br-companies-table', BrCompaniesTable);
Vue.component('br-companies-pagination', BrCompaniesPagination);

function noAccents(query) {
    // https://stackoverflow.com/a/37511463
    return query.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

window.noAccents = noAccents;

// To be able to target elements in your existing html/erb templates,
// add <%= javascript_pack_tag 'brazillian_companies/app' %> to your layout
// document.addEventListener('DOMContentLoaded', () => {    
//     const brazilianCompaniesApp = new Vue({
// 	el: '#brazilian-companies-ui',
// 	store,
// 	router
//     });
// });

// In case we want to load Vue through turbolinks one day...
//
// If the project is using turbolinks, install 'vue-turbolinks':
//
// yarn add vue-turbolinks
//
// Then uncomment the code block below:
//
// import TurbolinksAdapter from 'vue-turbolinks'
// import Vue from 'vue/dist/vue.esm'
// import App from '../app.vue'
//
// Vue.use(TurbolinksAdapter)
//
// document.addEventListener('turbolinks:load', () => {
//   const app = new Vue({
//     el: '#hello',
//     data: () => {
//       return {
//         message: "Can you say hello?"
//       }
//     },
//     components: { App }
//   })
// })




