export class AtmoMapping {

    constructor (source) {
        this.mapping = axios.get(source).then(response => { return  response.data; });
        this.keyValueFields = ['value', 'text'];
    }
    
    translate (valueList, inverse = false) {
        var currentFields = this.keyValueFields;
        if (inverse) { currentFields = this.keyValueFields.reverse(); }
        return this.mapping.then(data => {
            return data.filter( entry => valueList.includes( entry[currentFields[0]] ) )
                .map(entry => entry[currentFields[1]]);
        });
    }
    
}
