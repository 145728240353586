<template>
  <div
      :id=id      
      class="plots">
  </div>
</template>

<script>
 import { spec as uf_vegaspec } from '../assets/vega/uf_mun_choice.vega';
 export default {
     props: ['id', 'value', 'source', 'featureName'],
     data () {
         return {             
             vegaview: null,
             selected: this.value ? this.value : []
         }
     },
     computed: {
         mapData () {
             if (this.vegaview) {
                 return this.vegaview.data('ufmap')
             }
             else {
                 return null
             }
         },
         citiesFromOtherStates () {
             return this.value.filter(city => !city.startsWith(this.mapData[0].id.substr(0,2)))
         }
     },
     methods: {
         updateSelected (name, value) {
	     if ( value.length == 2 ) {
                 this.vegaview.runAsync().then( r => {
                     var newSelection = this.vegaview.data('munselected').map(m => m.id)
                     this.selected = newSelection.concat(this.citiesFromOtherStates)
                     this.$emit('input', this.selected)
                     this.$emit('change', this.selected)
                 })
	     }
         },
         getMapFrame(source) {
             const promise = axios.get(source)
             return promise.then(r => {                
                 const map = r.data
                 let lonScale = 45000.0/Math.abs(map.bbox[0]-map.bbox[2])
		 let latScale = 32000.0/Math.abs(map.bbox[1]-map.bbox[3])
		 let scale = Math.min(lonScale, latScale)
		 let centerX0 = (map.bbox[0]+map.bbox[2])/2
		 let centerY0 = (map.bbox[1]+map.bbox[3])/2
                 return [scale, centerX0, centerY0]
             })
         },
         drawMap() {
	     this.getMapFrame(this.source).then(r => {
                 let [scale, centerX0, centerY0] = r
                 VegaSpec.setVegaSpecDataMap(uf_vegaspec, 'ufmap', this.source, this.featureName)
	         VegaSpec.setVegaSpecSignalValue(uf_vegaspec, 'scale', scale)
	         VegaSpec.setVegaSpecSignalValue(uf_vegaspec, 'centerX', centerX0)
	         VegaSpec.setVegaSpecSignalValue(uf_vegaspec, 'centerY', centerY0)
	         this.draw().then(r => { this.drawSelected() })                 
             })	     
	 },
	 draw() {	    
	     var vegatthandler = new vegaTooltip.Handler();
	     this.vegaview = new vega.View(vega.parse(uf_vegaspec))
	    			     .logLevel(vega.Warn)
				     .renderer('svg')
				     .initialize('#'+this.id)
				     .tooltip(vegatthandler.call)
				     .hover()
				     .addSignalListener('toggleselect', this.updateSelected)
	     return this.vegaview.runAsync()
	 },
         drawSelected() {             
	     if ( this.mapData ) {
                 var addedCities = this.mapData.filter(m => this.selected.includes(m.id));
                 this.vegaview
                     .change('munselected',
                             vega.changeset().remove( d => true ).insert(addedCities))
                     .run()
             }
         }
     },
     watch: {
         source () { if ( this.source.length > 0 ) { this.drawMap() } },
         selected () { this.drawSelected() },
         value () { this.selected = this.value }
     }
 }
</script>

<style>
 .plots {
     width: 100%;
 } 
</style>
