export const BR_POLITICAL_MACRO = [
    {
        id: "norte",
        source: [{ text: 'Pará', value: 'PA' },
                 { text: 'Amazonas', value: 'AM' },
                 { text: 'Rondônia', value: 'RO' },
	         { text: 'Tocantins', value: 'TO' },
	         { text: 'Acre', value: 'AC' },
	         { text: 'Amapá', value: 'AP' },
	         { text: 'Roraima', value: 'RR' }],
        header: "Norte",
        options: [],
        value: [],
        selectAll: []
    },
    {
        id: "nordeste",
        source: [{ text: 'Bahia', value: 'BA' },
                 { text: 'Pernambuco', value: 'PE' },
		 { text: 'Ceará', value: 'CE' },		 
		 { text: 'Maranhão', value: 'MA' },
		 { text: 'Paraíba', value: 'PB' },
		 { text: 'Rio Grande do Norte', value: 'RN' },
		 { text: 'Alagoas', value: 'AL' },
		 { text: 'Piauí', value: 'PI' },
                 { text: 'Sergipe', value: 'SE' }],
        header: "Nordeste",
        options: [],
        value: [],
        selectAll: []
    },
    {
        id: "centro-oeste",
        source: [{ text: 'Goiás', value: 'GO' },
		 { text: 'Mato Grosso', value: 'MT' },
		 { text: 'Distrito Federal', value: 'DF' },
		 { text: 'Mato Grosso do Sul', value: 'MS' }],
        header: "Centro Oeste",
        options: [],
        value: [],
        selectAll: []
    },
    {
        id: "sudeste",
        source: [{ text: 'São Paulo', value: 'SP' },
		 { text: 'Minas Gerais', value: 'MG' },
		 { text: 'Rio de Janeiro', value: 'RJ' },
                 { text: 'Espírito Santo', value: 'ES' }],
        header: "Sudeste",
        options: [],
        value: [],
        selectAll: []
    },
    {
        id: "sul",
        source: [{ text: 'Paraná', value: 'PR' },
                 { text: 'Santa Catarina', value: 'SC' },
                 { text: 'Rio Grande do Sul', value: 'RS' }],
        header: "Sul",
        options: [],
        value: [],
        selectAll: []         
    }   
];

