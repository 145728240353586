<template>
  <b-card footer-tag="footer" header-tag="header" footer-class="mb-0" border-variant="0" header-bg-variant="white" header-class="py-3">
    <template #header>
      <b-container>
        <b-row align-h="between">
          <b-col align-v="middle" cols="11" class="px-lg-3 p-0">
            <span>
              <span class="h3">
                {{title}}
              </span>
              <InfoIcon
                  v-if="helpMessage || showInfoIcon"
                  id="info-icon"
                  :help-message="helpMessage"
                  :info-icon-action="infoIconAction">
              </InfoIcon>
            </span>
          </b-col>
          <b-col cols="1" class="d-flex justify-content-end px-lg-3 p-0">
          <b-button-group>
            <b-button variant="secondary" @click="$emit('hide')"><i class="fa fa-times"></i></b-button>
          </b-button-group>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <div>
      <slot>This is the base filter. Use me in your own component.</slot>
    </div>
    <b-button class="btn-blue mt-4" variant="none" @click="$emit('hide')">Adicionar</b-button>
  </b-card>
</template>

<script>
 import InfoIcon from './InfoIcon';
 export default {
     components: {InfoIcon},
     props: ["title", "id", "userData", "helpMessage","showInfoIcon","infoIconAction"]
 }
</script>
