<template>
  <b-row align-h="center">
    <b-col cols="10">
      <b-form-group
          :label="label"       
          :valid-feedback="validFeedback"
          :invalid-feedback="invalidFeedback"
          :state="checkInput">
        <b-form-input
            v-model="rawInput" debounce="350">
        </b-form-input>
        <b-form-text>
          <div v-for="line in helpText"> {{ line }}</br></div>          
        </b-form-text>
      </b-form-group>
    </b-col>
  </b-row>  
</template>

<script>
export default {  
  props: {
    value: Array,
    label: {
      type: String,
      default: ''
    },
    validFeedback: {
      type: String,
      default: 'Ok!'
    },
    invalidFeedback: {
      type: String,
      default: 'Não válido!'
    },
    minLength: {
      type: Number,
      default: 1
    },
    maxLength: {
      type: Number,
      default: 14
    },
    helpText: {
      type: Array      
    }
  },
  data() {
    return {
      rawInput: '',
    }
  },
  computed: {
    checkInput() {
      return this.checkLimitsAndUpdate(this.rawInput)      
    }
  },  
  methods: {
    checkLimitsAndUpdate(rawInput) {
      var sanitizedList = this.sanitizedNumberList(rawInput)
      if (sanitizedList.every( item => item.length>=this.minLength & item.length<=this.maxLength)) {
        this.$emit('input', this.uniqueAndValid(sanitizedList))
        return true
      } else {
        this.$emit('input', this.uniqueAndValid(sanitizedList))
        return false
      }
    },    
    sanitizedNumberList(rawInput) {
      return rawInput.split(",")
                     .map( item => item.replace(/\D/g,"") )
                     .filter( item => item.length>0 )
    },
    uniqueAndValid(list) {
      return [... new Set(list.filter(item => item.length>=this.minLength & item.length<=this.maxLength))]
    }
  },
  mounted() {
    if (this.value !== undefined) {
      this.rawInput = this.value.join()
    }
  }
}
</script>
