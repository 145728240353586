<template>
  <div>
    <b-input-group>
      <b-form-input type="text" placeholder="Digite aqui sua busca por CNAEs (ou escolha diretamente na árvore abaixo)" v-model="cnaeTreeFilter" class="filter-field"></b-form-input>
    </b-input-group>
    <br/>
    <span>
      <b-button size="sm" variant='info' @click="treeCheckMatched()"> Marcar todos encontrados</b-button>
      <b-button size="sm" variant='secondary' @click="treeUncheckMatched()"> Desmarcar todos encontrados</b-button>
      <b-button size="sm" variant='danger' @click="treeUncheckAll()"> Desmarcar tudo </b-button>
    </span>
    <b-card-body>
      <!-- The tree component is provided by LiquorTree -->
      <tree
	class="tree-highlights"
	  ref="cnaetree"
	  :filter="cnaeTreeFilter"
	  :options="cnaeTreeOptions"
          @node:checked="updateCnaeList"
          @node:unchecked="updateCnaeList"></tree>
      <!-- end of LiquorTree component -->
    </b-card-body>
  </div>
</template>

<script> 
export default {
    props: {
        value: Array,
        dataUrl: {
            default: '/data/cnpj/cnae_tree_full.json',
            type: String
        }
    },
    data() {
        return {
            title: "Filtro por ramo de atividade (CNAE):",
            cnaeTreeOptions: {
                fetchData: this.dataUrl,
                checkbox: true,
                filter: {
                    emptyText: 'Nenhum CNAE encontrado.',
                    matcher(query, node) {
                        // Test if query corresponds to node text ignoring numbers, case and diacritics
                        // noAccents is defined in app.js
                        const textMatch = query.replace(/[\d\.-\/:]/g,"").length && new RegExp(noAccents(query.replace(/\d/g,"")),'i').test(noAccents(node.text));
                        // Test if query corresponds to node cnae code tag taking only numbers
                        const codeMatch = query.replace(/\D/g,"").length && new RegExp("^"+query.replace(/\D/g,"")).test(node.data.cnaetag);
                        return textMatch || codeMatch
                    }
                }
            },
            cnaeTreeFilter: '',
            checkedCnaes: this.value,
            valueUpdating: false
        }
    },
    watch: {
        value () {
            if ( this.value.length == 0 ) {
                this.cnaeTreeFilter = ''
            }
            this.checkedCnaes = this.value
            this.valueUpdating = true
            this.$refs.cnaetree.findAll({ state: { checked: true } }).uncheck()
            this.checkCnaes()
            this.valueUpdating = false
        }
    },
    methods: {
        treeCheckMatched() {
            this.$refs.cnaetree.findAll({ state: { matched: true } }).check()
        },
        treeUncheckMatched() {
            this.$refs.cnaetree.findAll({ state: { matched: true } }).uncheck()
        },
        treeUncheckAll() {
            this.$refs.cnaetree.findAll({ state: { checked: true } }).uncheck()
        },
        eliminateRedundantCnaes() {
            if (typeof this.$refs.cnaetree !== 'undefined') {
                var sel = this.$refs.cnaetree.findAll({ state: { checked: true } })
                    .map(node => node.data.cnaetag)
                    .filter(function (el) {
                        return el; })
                    .sort(function (a,b){ return a.length - b.length })
                var ii = 0
                var result = sel
                while ( ii < result.length-1) {
                    result = result.filter( cnae => !( new RegExp(result[ii]+'.+').test(cnae) ) )
                    ii++;
                }
                return result
            }
            else {
                return []
            }
        },
        updateCnaeList() {
            // For the tree component, the "check"/"uncheck" event is indistinguishble
            // if it comes from a user click or programatically. So, to avoid infinite loops
            // and other quirks when updating the value field from outside this component,
            // we have implemented this "valueUpdating" flag. When on, it will bypass the
            // emision of the 'input' event.
            if ( !this.valueUpdating ) {
                this.checkedCnaes = this.eliminateRedundantCnaes();
                this.$emit('input', this.checkedCnaes)   
            }
        },
        checkCnaes() {
            if (typeof this.checkedCnaes !== 'undefined') {
                this.checkedCnaes.forEach( (cnae) => {
                    this.$refs.cnaetree.find( { data: { cnaetag: cnae } } ).check()
                })
            }
        }
    },
    mounted () {
        // Timeout is needed for component to be checked properly.
        // 400ms was empirically found to be acceptable in dev env.
        // TODO: Check in production
        this.$on('tree:data:received', setTimeout( this.checkCnaes, 800 ) )
    }
}
</script>
