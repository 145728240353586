<template>
  <b-table
    id="cnpj-table"
    responsive="sm"
    ref="brazilianCompaniesTable"
    :items="fetchCompanies"
    :fields="fields"
    :sort-by.sync="sortBy"
    :per-page="perPage"
    :current-page="currentPage"
    empty-text="Nenhuma empresa encontrada"
    fixed
    striped
    show-empty>


    <template #table-colgroup="scope">
      <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'detalhes' ? '100px' : field.key === 'id' ? '50px' : ''}" >
    </template>

    <template #cell(id)="data">
      {{ data.index + 1 + (currentPage-1)*perPage }}
    </template>

    <template v-slot:head(nome)="data">
      <span v-html="data.label"></span>
    </template>

    <template v-slot:cell(nome)="data">
      {{ data.item.razao_social | capitalize }} <br>
      {{ data.item.nome_fantasia | capitalize  }}
    </template>

    <template v-slot:head(cnae_fiscal)="data">
      <span v-html="data.label"></span>
    </template>

    <template v-slot:cell(cnae_fiscal)="data">
      {{ data.item.cnae_fiscal_pretty }} <br> {{ data.item.cnae_text }}
    </template>

    <template v-slot:head(endereco)="data">
      <span v-html="data.label"></span>
    </template>

    <template v-slot:cell(endereco)="data">
      <b-row v-if="data.item.telefone_1.length > 0">
        <b-col cols="1">
          <a v-if="isMobilePhone(data.item.telefone_1)"
             :href="whatsAppUrl(data.item.ddd_1 + data.item.telefone_1)"
             target="_blank">
            <i class="h4 fa fa-whatsapp"></i>
          </a>              
          <i v-else class='fa fa-phone'></i>
        </b-col>
        <b-col>({{ data.item.ddd_1 }}) {{ data.item.telefone_1 | fix-phone }}
          <b-badge pill v-if="countExtraPhones(data.item)" @click="showCompanyDetails(data)" href="#" variant="info">
            <i class="fa fa-plus"></i> {{ countExtraPhones(data.item) }}
          </b-badge>
        </b-col>
      </b-row>
      <b-row v-if="data.item.email">
        <b-col cols="1"><i class='fa fa-envelope'></i></b-col>
        <b-col>
          <span v-if="emailTypoSuspect(data.item)"
                :id="'typo_' + data.item.email"
                @click="showCompanyDetails(data)">
            {{ data.item.email.toLowerCase() }} <i class='fa fa-exclamation-triangle'></i>
            <b-popover :target="'typo_' + data.item.email" triggers="hover" placement="bottom">
              É possível que este e-mail contenha um erro de digitação. Clique em <em>"+ Detalhes"</em> para ver sugestões de correção.
            </b-popover>
          </span>
          <span v-else="">{{ data.item.email.toLowerCase() }}</span>
          <b-badge pill v-if="countExtra(data.item,'email')" @click="showCompanyDetails(data)" href="#" variant="info">
            <i class="fa fa-plus"></i> {{ countExtra(data.item,'email') }}
          </b-badge>
        </b-col>
      </b-row>
      <b-row v-if="getWebsite(data.item)">
        <b-col cols="1"><i class='fa fa-globe'></i></b-col>
        <b-col>
          <a :href="getWebsite(data.item)" target="_blank">
            {{ getWebsite(data.item) }}
          </a>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1"><i class='fa fa-building'></i></b-col>
        <b-col>{{ data.item.tipo_logradouro | capitalize  }} {{ data.item.logradouro | capitalize  }} {{ data.item.numero }} {{ data.item.complemento | capitalize  }}
          <b-badge pill v-if="countExtra(data.item, 'address')" @click="showCompanyDetails(data)" href="#" variant="info">
            <i class="fa fa-plus"></i> {{ countExtra(data.item, 'address') }}
          </b-badge>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="1"></b-col>
        <b-col><span v-if="data.item.bairro">{{ data.item.bairro | capitalize  }},</span> <span v-if="data.item.bairro">CEP: {{ data.item.cep | fix-cep }},</span></b-col>
      </b-row>
      <b-row>
        <b-col cols="1"></b-col>
        <b-col><span> {{ data.item.municipio }} - {{ data.item.uf }} </span></b-col>
      </b-row>
      <b-row>
        <b-col cols="1"></b-col>
        <b-col>
          <a v-for="network in getSocialNetworks(data.item)"
             :key="network.key"
             :href="network.value"
             target="_blank">
            <i v-if="network.url_type != 'website'" :class="socialNetworkIconClass[network.url_type] + ' pr-2'" style="font-size: 1.5em;"></i>
          </a>
        </b-col>
      </b-row>
    </template>
  <template v-slot:cell(detalhes)="data">
    <b-button size="sm" @click="showCompanyDetails(data)" class="mr-2" title="Exibir todos os detalhes da Empresa">
      Detalhes&nbsp;<i v-if="data.detailsShowing" class="bi bi-caret-up"></i><i v-if="!data.detailsShowing" class="bi bi-caret-down-fill"></i>
    </b-button>
   
    <button type="button" class="btn btn-opntd-sm mt-3 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      Ações
    </button>
    <div class="dropdown-menu dropdown-menu-right shadow-sm">
      <div v-for="list in recordLists" class="dropdown-item">
        <a v-if="list.cnpjs.includes(data.item.cnpj)" @click.prevent="removeCnpjFromList(list.id, data.item.cnpj)">Remover da lista: {{ list.name }}</a>
        <a v-else @click.prevent="addCnpjToList(list.id, data.item.cnpj)">Adicionar à lista: {{ list.name }}</a>        
      </div>
    </div>    

  </template>
  <template #table-busy>
    <div class="text-center text-primary my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong> Buscando novas oportunidades de negócio para você </strong>
      <br/>
      Só mais uns segundinhos...
    </div>
  </template>   
  <template v-slot:row-details="data">
    <hr>
      <b-container fluid="sm">
        <b-row class="text-center">
          <b-col>
            <h2>{{ data.item.razao_social | capitalize }}</h2>
          </b-col>
        </b-row>
        <b-row class="text-center">
          <b-col>
            <h4 v-if="data.item.nome_fantasia.length > 0">{{ data.item.nome_fantasia | capitalize }}</h4>
          </b-col>
        </b-row>
        <p class="h5"> <strong>CNPJ:</strong> {{ data.item.cnpj | fix-cnpj}} <span v-if="data.item.matriz_filial"> - {{ data.item.matriz_filial }} </span></p> <br>
        <p class="h5"> <strong>CNAE Fiscal:</strong> {{ data.item.cnae_fiscal_pretty }} - {{ data.item.cnae_text }} </p> <br>
        <b-row>
          <b-col  cols="4">
            <h5>Contato:</h5>
            <b-row v-if="data.item.telefone_1">              
              <b-col cols="1">
                <a v-if="isMobilePhone(data.item.telefone_1)"
                   :href="whatsAppUrl(data.item.ddd_1 + data.item.telefone_1)"
                   target="_blank">
                  <i class="h4 fa fa-whatsapp"></i>
                </a>              
                <i v-else class='fa fa-phone'></i>                    
              </b-col>
              <b-col>({{ data.item.ddd_1 }}) {{ data.item.telefone_1 | fix-phone }}</b-col>
            </b-row>
            <b-row v-if="data.item.telefone_2">
              <b-col cols="1">
                <a v-if="isMobilePhone(data.item.telefone_2)"
                   :href="whatsAppUrl(data.item.ddd_2 + data.item.telefone_2)"
                   target="_blank">
                  <i class="h4 fa fa-whatsapp"></i>
                </a>              
                <i v-else class='fa fa-phone'></i>                    
              </b-col>
              <b-col>({{ data.item.ddd_2 }}) {{ data.item.telefone_2 | fix-phone }}</b-col>
            </b-row>
            <b-row v-if="data.item.telefone_3">
              <b-col cols="1">
                <a v-if="isMobilePhone(data.item.telefone_3)"
                   :href="whatsAppUrl(data.item.ddd_3 + data.item.telefone_3)"
                   target="_blank">
                  <i class="h4 fa fa-whatsapp"></i>
                </a>              
                <i v-else class='fa fa-phone'></i>                    
              </b-col>
              <b-col>({{ data.item.ddd_3 }}) {{ data.item.telefone_3 | fix-phone }}</b-col>
            </b-row>
            <b-row v-if="data.item.email">
              <b-col cols="1"><i class='fa fa-envelope'></i></b-col>
              <b-col>{{ data.item.email.toLowerCase() }}</b-col>
            </b-row>
            <b-row>
              <b-col cols="1"><i class='fa fa-building'></i></b-col>
              <b-col>{{ data.item.tipo_logradouro | capitalize  }} {{ data.item.logradouro | capitalize  }} {{ data.item.numero }} {{ data.item.complemento | capitalize  }}</b-col>
            </b-row>
            <b-row>
              <b-col cols="1"></b-col>
              <b-col><span v-if="data.item.bairro">{{ data.item.bairro | capitalize  }},</span> <span v-if="data.item.bairro">CEP: {{ data.item.cep | fix-cep }},</span></b-col>
            </b-row>
            <b-row>
              <b-col cols="1"></b-col>
              <b-col><span> {{ data.item.municipio }} - {{ data.item.uf }} </span></b-col>
            </b-row>
            <b-row class="pt-2 pb-2" align-h="center">
              <b-col cols="1"></b-col>
              <b-col>
                <b-button variant="info" :href="getGoogleSearchUrl(data.item)" target="_blank" size="sm">Pesquise esta empresa na internet</b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <h5>Informações Administrativas:</h5>
            <b-row>
              <b-col>
                <p class="mb-0" v-if="data.item.situacao_cadastral"><strong>Situação:</strong> {{ data.item.situacao_cadastral }}</p>
              </b-col>
              <b-col>
                <p class="mb-0" v-if="data.item.data_inicio_atividade"><strong>Abertura:</strong> {{ data.item.data_inicio_atividade | fix-date }}</p>
              </b-col>
              <b-col cols="5">
                <p class="mb-0" v-if="data.item.data_situacao"><strong>Última Atualização:</strong> {{ data.item.data_situacao | fix-date }}</p>
              </b-col>
            </b-row>
            <p class="mb-0" v-if="data.item.porte_pretty"><strong>Porte:</strong> {{ data.item.porte }}  </p>
            <b-row>
              <b-col>
                <p class="mb-0" v-if="data.item.opc_simples"> {{ data.item.opc_simples }} </p>
              </b-col>
              <b-col>
                <p class="mb-0" v-if="!data.item.opc_simples.startsWith('N') && validDate(data.item.data_opc_simples)"><strong>Data de Opção pelo Simples:</strong> {{ data.item.data_opc_simples | fix-date }} </p>
                <p class="mb-0" v-if="data.item.opc_simples.startsWith('N') && validDate(data.item.data_exc_simples)"><strong>Data de Exclusão do Simples:</strong> {{ data.item.data_exc_simples | fix-date }} </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p class="mb-0" v-if="data.item.opc_mei"> {{ data.item.opc_mei }} </p>
              </b-col>
              <b-col>
                <p class="mb-0" v-if="!data.item.opc_mei.startsWith('N') && validDate(data.item.data_opc_mei)"><strong>Data de Opção pelo MEI:</strong> {{ data.item.data_opc_mei | fix-date }} </p>
                <p class="mb-0" v-if="data.item.opc_mei.startsWith('N') && validDate(data.item.data_exc_mei)"><strong>Data de Exclusão do MEI:</strong> {{ data.item.data_exc_mei | fix-date }} </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p class="mb-0" v-if="data.item.natureza_juridica"><strong>Natureza Jurídica:</strong> {{ data.item.natureza_juridica }}  </p>
              </b-col>
              <b-col>
                <p class="mb-0" v-if="data.item.motivo_situacao_pretty"><strong>Motivo da Situação:</strong> {{ data.item.motivo_situacao_pretty }}  </p>
              </b-col>
            </b-row>
            <p class="mb-0" v-if="data.item.capital_social"><strong>Capital Social:</strong> R$ {{ data.item.capital_social | fix-capital-social }}  </p>
            <b-row v-if="data.item.situacao_especial">
              <b-col><p class="mb-0"><strong>Situação Especial:</strong> {{ data.item.situacao_especial_pretty }}  </p></b-col>
              <b-col><p class="mb-0"><strong>Data da Situação Especial:</strong> {{ data.item.data_sit_especial | fix-date }} </p></b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
      <b-container class="mt-2" v-if="data.item.extras.length">
        <b-row>
          <h5>Contatos extras:</h5>
          <b-table
              :items="data.item.extras"
              :fields="fieldsTableExtraContacts"
              :tbody-tr-class="extrasRowClass"
              :tbody-tr-attr="extrasFormatTypo"
              sticky-header="200px"
              sort-by="order"
              responsive
              borderless
              small
              hover>
            <template #table-colgroup="scope">
              <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'type' ? '100px' : ''}">
            </template>
            <template v-slot:cell(type)="extra">
              <div class="ml-2">
                <a v-if="extra.item.field_name == 'url'" :href="extra.item.value" target="_blank">
                  <i :class="contactIconClass(extra.item)"></i>
                </a>
                <div v-else>
                  <a v-if="extra.item.field_name=='phone' && isMobilePhone(extra.item.value)" 
                     :href="whatsAppUrl(extra.item.value)"                       
                     target="_blank">
                    <i class="h4 fa fa-whatsapp"></i>
                  </a>
                  <i v-else :class="contactIconClass(extra.item)"></i>
                </div>
              </div>              
            </template>
            <template v-slot:cell(value)="extra">
              <a v-if="extra.item.field_name == 'url'" :href="extra.item.value" target="_blank">
                {{ extra.item.value }}
              </a>
              <span v-else> {{ extra.item.value }} </span>
            </template>
            <template v-slot:cell(source)="extra">
              {{ getExtraSource(extra.item) }}
            </template>
            <!-- <template v-slot:cell(info)="extra">
                 {{ getExtraInfo(extra.item) }}
                 </template> -->
          </b-table>
        </b-row>
      </b-container>
      <b-container class="mt-2">
        <b-row>
          <b-col>
            <h5>CNAEs Secundários:</h5>
            <b-table
                :items="fetchCnaesSecundarios"
                :fields="fieldsTableCnaesSecundarios"
                small
                sticky-header
                responsive
                show-empty>
              <template #table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'cnae' ? '100px' : ''}">
              </template>
              <template v-slot:cell(cnae)="cnaes_secundarios_data">
                {{ cnaes_secundarios_data.item.cnae | fix-cnae }}
              </template>
              <template v-slot:cell(description)="cnaes_secundarios_data">
                {{ cnaes_secundarios_data.item.description }}
              </template>
              <template #empty="scope">
                Empresa não possui Cnaes secundários
              </template>
            </b-table>
          </b-col>
          <b-col>
            <h5>Quadro Societário:</h5>
            <b-table
                :items="fetchSocios"
                :fields="fieldsTableSocios"
                small
                sticky-header
                show-empty>
              <template v-slot:cell(nome_socio)="socios_data">
                {{ socios_data.item.nome_socio | capitalize }}
              </template>
              <template v-slot:cell(data_entrada)="socios_data">
                {{ socios_data.item.data_entrada | fix-date }}
              </template>
              <template v-slot:cell(socio_search)="socios_data">
                <div class="text-center my-auto">
                  <b-button variant="outline-primary" 
                            size="sm" 
                            @click="find_partner_companies(socios_data.item.nome_socio, socios_data.item.cpf_cnpj)"                            
                            class="align-middle"
                            title="Pesquise todas as empresas deste sócio"
                            data-placement="right">                                
                    <i class='fa fa-search'></i>
                  </b-button>
                </div>
              </template>
              <template #empty="scope">
                Empresa não possui quadro societário registrado
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row class="text-center">
          <b-col>
            <CnpjMapGoogle
                :cnpj_location = "data.item.nome_fantasia + ' ' + data.item.tipo_logradouro + ' ' + data.item.logradouro + ' ' + data.item.numero + ', ' + data.item.municipio + '-' + data.item.uf">
            </CnpjMapGoogle>
          </b-col>
        </b-row>
      </b-container>
  </template>
  </b-table>
</template>


<script>
import CnpjMapGoogle from './CnpjMapGoogle';

const socialNetworkIconClass = {"foursquare": "fa fa-foursquare",
                                  "linkedin": "fa fa-linkedin",
                                  "tripadvisor": "fa fa-tripadvisor",
                                  "pinterest": "fa fa-pinterest",
                                  "wordpress": "fa fa-wordpress-simple",
                                  "tiktok": "fab fa-tiktok",
                                  "spotify": "fa fa-spotify",
                                  "whatsapp": "fa fa-whatsapp",
                                  "facebook": "fa fa-facebook-square",
                                  "instagram": "fa fa-instagram",
                                  "twitter": "fa fa-twitter-square",
                                  "youtube": "fa fa-youtube",
                                  "twitch": "fab fa-twitch",
                                  "website": "fa fa-globe"};

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}  

export default {
  components: { CnpjMapGoogle },
  props: {
    value: Object,
    recordLists: Array,
    currentPage: Number,
    internalReferrer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {      
      sortBy: 'nome',
      sortDesc: false,      
      perPage: 25,
      queryUrl: '/companies_data?',      
      fields: [
             'id',
             { key: 'nome', label: 'Razão Social <br> Nome Fantasia', sortable: false },
             { key: 'cnae_fiscal', label: 'CNAE Fiscal <br> Descrição', sortable: false },
             { key: 'endereco', label: 'Contato <br> Endereço', sortable: false },
             { key: 'detalhes', label: '', sortable: false, details_loaded: false}
      ],
      fieldsTableSocios: [
          {key: 'nome_socio', label: 'Nome do Sócio'},
          {key: 'data_entrada', label: 'Data de Entrada'},
          {key: 'qualificacao', label: 'Qualificação'},
          {key: 'socio_search', label: '', sortable: false, class: 'align-middle'}
      ],
      fieldsTableCnaesSecundarios: [
          {key:'cnae' , label:'CNAE'},
          {key:'description', label:'Denominação'}
      ],
      fieldsTableExtraContacts: [
          {key:'type' , label:'Tipo'},
          {key:'value', label:'Contato'},
          {key:'source', label:'Fonte'}
      ],
      currentTableItems: [],
      tableErrorFlag: false,
      tableErrorResponse: {},
      currentCnpj: '',
      socialNetworkIconClass
    }
  },
  methods: {
    refresh() {
      this.$refs.brazilianCompaniesTable.refresh()
    },
    fetchCompanies(ctx) {
      if (this.internalReferrer && this.value.cnpj.length==0) {
        this.currentTableItems = []
        return []
      }      
      let newQuery = this.queryUrl 
                     + '&currentPage=' + ctx.currentPage
                     + '&perPage=' + ctx.perPage
                     + '&sortBy=' + ctx.sortBy
                     + '&sortDesc=' + ctx.sortDesc
                     + '&apiUrl=' + ctx.apiUrl
      if (this.internalReferrer) {
        newQuery += '&considerInternal=true'
      }
      const promise = axios.get(newQuery)
      return promise.then(response => {
        this.tableErrorFlag = false
        let tableItems = this.orderTableItems(response.data.companies.data.map(item => item.attributes))
        let cnpjList = tableItems.map(item => item.cnpj)
        return this.fetchExtras(cnpjList)
                   .then(extras => {
                       tableItems.forEach(item => {
                           item.extras = item.extras
                                             .concat( extras.filter( extra => extra.cnpj == item.cnpj ) )
                           item.extras.sort(this.compareExtras)
                       })
                       this.currentTableItems = tableItems
                       return tableItems
                   }).catch( error => { console.log(error)
                       throw( error ) })
      }).catch( error => {
        this.tableErrorFlag = true
        this.tableErrorResponse = error.response
        return []
      })
    },
    orderTableItems(items) {
      if (this.value.sort_field=='data_inicio_atividade') {
        items.sort((a,b) => this.compareOpeningDates(a,b,this.value.sort_order))
      }
      else {
        items.sort((a,b) => Number(a.cnpj) - Number(b.cnpj))
      }
      return items
    },
    compareOpeningDates(a,b,order) {
      if (order.toLowerCase().startsWith('asc')) {
        return new Date(a.data_inicio_atividade) - new Date(b.data_inicio_atividade)
      }
      else {
        return new Date(b.data_inicio_atividade) - new Date(a.data_inicio_atividade)
      }
    },    
    fetchCnaesSecundarios(ctx) {
      const newQuery = '/cnaes_secundarios_data?' + '&cnpj=' + this.currentCnpj
      const cnaes_secundarios_promise = axios.get(newQuery)
      return cnaes_secundarios_promise.then(response => {
        return response.data
      })
    },
    fetchSocios(ctx) {
      var newQuery = '/socios_data?' + '&cnpj=' + this.currentCnpj
      const socios_promise = axios.get(newQuery)
      return socios_promise.then(response => {
        return response.data
      })
    },
    fetchExtras(cnpjList) {
      return axios.post('/enricher/enrich_cnpj_entries', {'cnpj': cnpjList})
                  .then(response => {
                    return response.data.data.map(item => item.attributes)
                  })
    },
    countExtra(dataItem, field) {
      let count = 0
      dataItem.extras.forEach(extra => {
        if ( extra.field_name == field ) { count++ }
      })
      return count
    },
    countExtraPhones(dataItem) {
      let count = 0
      if ( dataItem.telefone_2 ) { count++ }
      if ( dataItem.telefone_3 ) { count++ }
      count += this.countExtra(dataItem, 'phone')
      return count
    },
    showCompanyDetails(data) {
      this.currentCnpj = data.item.cnpj
      data.toggleDetails()
    },
    emailTypoSuspect(dataItem) {
      const emails = dataItem.extras.filter(item => item.field_name == 'email')
      return emails.find(item => this.extraMessageIncludes(item, 'typo')) ? true : false
    },
    extraMessageIncludes(extraItem, query) {
      let sourceMessage = extraItem.source_message
      return ( sourceMessage && sourceMessage.includes(query) )
    },
    getWebsite(dataItem) {
      const website = dataItem.extras.find(item => item.url_type == 'website')
      return website ? website.value : ''
    },
    getSocialNetworks(dataItem) {
      const urls = dataItem.extras
                           .filter(item => item.field_name == 'url')
      urls.forEach(item => { item.key = uuidv4() })
      return urls
    },
    getGoogleSearchUrl(dataItem) {      
      let nomeEmpresa = ''
      if (dataItem.nome_fantasia.length > 0) {        
        nomeEmpresa = this.prepareNameForSearch(dataItem.nome_fantasia)                
      }
      else {
        nomeEmpresa = this.prepareNameForSearch(dataItem.razao_social)        
      }            
      return 'http://www.google.com/search?q=' + nomeEmpresa + '+"' + dataItem.municipio + '"+' + dataItem.uf + "+-CNPJ"
    },
    prepareNameForSearch(nameStr) {            
      // Remove any possible CPF numbers at the end of the name
      nameStr = nameStr.trim().replace(/ \d{11}$/,'')
      // Escape ampersands
      nameStr = nameStr.replace(/&/,'%26')
      // Change to titlecase and return name in between double-quotes      
      return '"' + toTitleCase(nameStr) + '"'
    },
    extrasRowClass(item, _type) {
      if ( !item || _type !== 'row') return
      if ( this.extraMessageIncludes(item,'typo') ) return 'table-success'
    },
    extrasFormatTypo(item, _type) {
      if ( !item || _type !== 'row') return
      if ( this.extraMessageIncludes(item,'typo') ) return {'title': 'Sugestão de correção para erro de digitação'}
    },
    contactIconClass(extraItem) {
      let classDesc = ''
      if (extraItem.field_name=='phone') {
        classDesc = 'fa fa-phone'
      }
      else if (extraItem.field_name=='email') {
        classDesc = 'fa fa-envelope'
      }
      else if (extraItem.field_name=='address') {
        classDesc = 'fa fa-building'
      }
      else if (extraItem.field_name=='url') {
        classDesc = socialNetworkIconClass[extraItem.url_type]
      }
      return classDesc
    },
    getExtraSource(extraItem) {
      return this.extraMessageIncludes(extraItem, 'fromReceitaFederal') ? 'Receita Federal' : 'Oportunidados'
    },
    compareExtras(a,b) {
      if ( b.field_name != a.field_name ) {
        return this.sortExtrasByFieldName(a,b)
      }
      else if ( this.extraMessageIncludes(b, 'typo') || this.extraMessageIncludes(a, 'typo') ) {
        return this.sortExtrasByMessage(a,b,'typo')
      }
      else if ( this.extraMessageIncludes(b, 'fromReceita') || this.extraMessageIncludes(a, 'fromReceita') ) {
        return this.sortExtrasByMessage(a,b,'fromReceita')
      }
      else if ( a.field_name == 'url' && b.url_type < a.url_type ) {
        return 1
      }
      return -1;
    },
    sortExtrasByFieldName(a,b) {
      const extraFieldNameOrder = ['email', 'phone', 'url']
      return extraFieldNameOrder.indexOf(a.field_name) - extraFieldNameOrder.indexOf(b.field_name)
    },
    sortExtrasByMessage(a,b,message) {
      if ( this.extraMessageIncludes(b, message) && !this.extraMessageIncludes(a, message) ) {
        return 1
      }
      else if ( !this.extraMessageIncludes(b, message) && this.extraMessageIncludes(a, message) ) {
        return -1
      }
      return 0
    },
    find_partner_companies (partner_name, partner_cnpj_cpf) {          
      const partner_filter = {nome_socio: partner_name, cnpj_cpf_socio: partner_cnpj_cpf};
      axios.post('/brazilian_companies_filters/create_for_js', partner_filter)
           .then(response => {
             window.open('/brazilian_companies', '_blank').focus();                   
           }).catch(error => {
             alert("Ops! É possível que seu navegador esteja impedindo nosso aplicativo de abrir uma nova aba. Permita que nosso site abra novas abas e clique novamente no botão de busca.")
           })
    },
    isEpoch(dateString) {
      return dateString.slice(0,4)=='1970' && dateString.slice(6,8)=='01' && dateString.slice(10,12)=='01'
    },
    validDate(dateString) {
      return !(dateString === null) && dateString.length == 12 && !this.isEpoch(dateString)
    },
    isMobilePhone(phone) {            
      const regex = /(55)?(\d{2})?(\d{8,9})/
      const m = phone.match(regex) || []            
      return (m.length>0 && m[m.length-1].startsWith('9'))
    },
    whatsAppUrl(phone) {
      if (!phone.startsWith('55')) {
        phone = '55' + phone
      }
      return 'https://wa.me/' + phone
    },
    addCnpjToList(record_list_id, cnpj) {                     
      const path = "/record_lists/" + record_list_id + "/add_cnpjs"
      axios.post(path, {'cnpjs': cnpj}, {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
           .then(response => {
             toastr.success(response.data.message)                                    
             this.updateRecordLists('add', record_list_id, cnpj)
           });
    },
    removeCnpjFromList(record_list_id, cnpj) {                     
      const path = "/record_lists/" + record_list_id + "/remove_cnpjs"
      axios.post(path, {'cnpjs': cnpj}, {headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}})
           .then(response => {
             toastr.warning(response.data.message)                  
             this.updateRecordLists('remove', record_list_id, cnpj)                  
           });                
    },
    updateRecordLists(action, list_id, cnpj) {
      this.recordLists.forEach( list => {
        if (list.id == list_id) {               
          if (action == 'add') {          
            list.cnpjs.push(cnpj)
          }
          else {                 
            list.cnpjs = list.cnpjs.filter( item => item != cnpj )
          }
        }
      })           
    },      
  }  
}
</script>