<template>
  <CheckboxPanel
      v-model="checkedStates"
      :categories="regions_and_states"
      name="size-checkbox-form">
  </CheckboxPanel>
</template>

<script>
 import CheckboxPanel from './BaseCheckboxPanel';
 import {BR_POLITICAL_MACRO as regions_and_states} from '../assets/constants/ui_categories';
 
 export default {
     components: { CheckboxPanel },
     props: ["value"],
     data() {
	 return {
             checkedStates: [],
             regions_and_states
	 }
     },
     watch: {
         checkedStates() {
             this.$emit('input', this.checkedStates)
         },
         value() {
             this.checkedStates = this.value
         }         
     },
     created () {
         this.checkedStates = this.value;
     }
 }
</script>
