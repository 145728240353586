<template>
  <b-row align-h="center">
    <b-col cols="5">
      <b-form-group>
        <b-form-group
            :label="texts.startLabel"
            :description="texts.startDescription"
        >
          <datepicker-br @input="dateChosen" v-model="startDate"></datepicker-br>
        </b-form-group>
        <b-form-group
            :label="texts.endLabel"
            :description="texts.endDescription"
        >
          <datepicker-br @input="dateChosen" v-model="endDate"></datepicker-br>
        </b-form-group>
        <b-form-text v-if="texts.general.length>0">
            {{ texts.general }}          
        </b-form-text>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
 import CustomDatePickerBr from './CustomDatePickerBr';
 export default {
     components: { "datepicker-br": CustomDatePickerBr },
     props: ["value", "texts"],
     data() {
	 return {
             startDate: '',
             endDate: ''
	 }
     },
     computed: {
         dateInterval: {
             get() {
                 return [this.startDate, this.endDate]    
             },
             set(newInterval) {
                 if (newInterval.length==2) {
                     this.startDate = newInterval[0]
                     this.endDate = newInterval[1]
                 }
             }             
         }
     },
     methods: {
         dateChosen() {
             this.$emit('input', this.dateInterval)
         }
     },
     watch: {
         value() {
             if ( !(this.value === undefined) ) {
                 this.dateInterval = this.value    
             }             
         }         
     },
     created () {
         if ( !(this.value === undefined) ) {
             this.dateInterval = this.value    
         }
     }
 }
</script>
