<template>
  <div class="cnpj-gmap"/>
</template>

<script>
 // import gmapsInit from '../utils/gmaps';
 export default {
     name: 'cnpj-gmap',
     props: [ 'cnpj_location' ],
     async mounted() {
         try {             
             const google = await gmapsInit();
             const geocoder = new google.maps.Geocoder();             
             const map = new google.maps.Map(this.$el);
             const marker = new google.maps.Marker(this.$el);
             // const service = new google.maps.places.PlacesService(map);             
             
             geocoder.geocode({ address: this.cnpj_location }, (results, status) => {
                 if (status !== 'OK' || !results[0]) {
                     throw new Error(status);
                 }
                 map.setCenter(results[0].geometry.location);
                 map.fitBounds(results[0].geometry.viewport);
                 map.setZoom(19);

                 new google.maps.Marker({
                     position: results[0].geometry.location,
                     map: map
                 }); 
             });
         } catch (error) {
             console.error(error);
         }
     },
 };
</script>
