<template>
  <b-form-datepicker
      show-decade-nav
      reset-button
      close-button      
      size="sm"
      locale="pt-BR"
      label-reset-button="Apagar"
      label-close-button="Fechar"
      label-no-date-selected="Nenhuma data selecionada"
      label-calendar="Calendário"
      label-current-month="Mês atual"
      label-help="Use também as setas para navegar nas datas"
      label-nav="Navegação no calendário"
      label-next-decade="Próxima década"
      label-next-month="Próximo mês"
      label-next-year="Próximo ano"
      label-prev-decade="Década anterior"
      label-prev-month="Mês anterior"
      label-prev-year="Ano anterior"
      :hide-header="true"
      @input="emitInput"
      v-model="date">    
  </b-form-datepicker>  
</template>

<script>
 export default {
     props: ["value"],
     data() {
	 return {
             date: ''
	 }
     },
     methods: {
         emitInput() {
             this.$emit('input', this.date)
         }
     },
     watch: {
         value() {
             if ( !(this.value === undefined) ) {
                 this.date = this.value    
             }             
         }         
     },
     created () {
         if ( !(this.value === undefined) ) {
             this.date = this.value    
         }
     }
 }
</script>
